/* eslint-disable jsx-a11y/anchor-is-valid */

/* eslint-disable jsx-a11y/anchor-has-content */
import classNames from 'classnames';
import Link from 'next/link';
import { FC, MouseEventHandler, ReactNode } from 'react';

import classes from './ArticleCard.module.scss';

interface ArticleCardProps {
  link?: string;
  linkTitle?: string;
  className?: string;
  onClick?: MouseEventHandler;
  children: ReactNode;
}

export const ArticleCard: FC<ArticleCardProps> = ({ link, linkTitle, className, children, onClick, ...props }) => (
  <article {...props} className={classNames(classes.root, { [classes.withLink]: !!link }, className)}>
    {children}
    {link && (
      <Link
        href={link}
        passHref
        className={classNames(classes.link, 'tileLink')}
        title={linkTitle}
        onClick={onClick}
        role="presentation"
        data-testid="ArticleCard-link"
      />
    )}
  </article>
);
