import classNames from 'classnames';
import { FC } from 'react';
import shallow from 'zustand/shallow';

import { PageProductListingFilters } from 'src/data/Contentful/ContentfulGetPageProductListing.types';
import { MICROCOPY } from 'src/data/microcopy/microcopyDictionary';
import { ArticleCard } from 'src/general/components/ArticleCard/ArticleCard';
import { CarouselSection } from 'src/general/components/Carousel/CarouselSection';
import { Image } from 'src/general/components/Image/Image';
import { getMicrocopy, getMicrocopyBulk } from 'src/stores/microcopyStore';
import { plpStoreLegacy } from 'src/stores/plpStoreLegacy';
import { getPlpUrlFromStoreFilters } from 'src/utils/plp/getPlpUrlFromStoreFilters';
import { pushToDataLayer } from 'src/utils/pushToDataLayer';

import { CarouselSectionLegacy } from '../CarouselSectionLegacy/CarouselSectionLegacy';
import classes from './CarouselOccasion.module.scss';

const BASE_IMAGE_PATH = 'https://img.carma.com.au/ui-assets/CarouselOccasion';

const enum OCCASION {
  BUSY_FAMILIES = 'busyFamilies',
  ADVENTURE_SEEKERS = 'adventureSeekers',
  CITY_DWELLING = 'cityDwelling',
  SUSTAINABLE = 'sustainable',
  ON_THE_TOOLS = 'onTheTools',
}

interface CarouselOccasionDefinition {
  occasion: OCCASION;
  image: string;
  cta: MICROCOPY;
  categories: PageProductListingFilters;
  title: string;
}

const tileDefinitions: CarouselOccasionDefinition[] = [
  {
    occasion: OCCASION.BUSY_FAMILIES,
    cta: MICROCOPY.HYGIENE_CAROUSEL_OCCASION_0_CTA,
    image: `${BASE_IMAGE_PATH}/busyFamilies.jpg`,
    categories: { bodyType: ['suv', 'wagon', 'people mover', 'sedan'] },
    title: 'Busy families',
  },
  {
    occasion: OCCASION.ADVENTURE_SEEKERS,
    cta: MICROCOPY.HYGIENE_CAROUSEL_OCCASION_1_CTA,
    image: `${BASE_IMAGE_PATH}/adventureSeekers.jpg`,
    categories: { bodyType: ['suv', 'ute', 'van'], driveType: ['4wd'] },
    title: 'Adventure seekers',
  },
  {
    occasion: OCCASION.CITY_DWELLING,
    cta: MICROCOPY.HYGIENE_CAROUSEL_OCCASION_2_CTA,
    image: `${BASE_IMAGE_PATH}/cityDwelling.jpg`,
    categories: { bodyType: ['hatch', 'coupe', 'convertible'] },
    title: 'City dwelling',
  },
  {
    occasion: OCCASION.SUSTAINABLE,
    cta: MICROCOPY.HYGIENE_CAROUSEL_OCCASION_3_CTA,
    image: `${BASE_IMAGE_PATH}/sustainable.jpg`,
    categories: { fuelType: ['electric', 'hybrid'] },
    title: 'Environment first',
  },
  {
    occasion: OCCASION.ON_THE_TOOLS,
    cta: MICROCOPY.HYGIENE_CAROUSEL_OCCASION_4_CTA,
    image: `${BASE_IMAGE_PATH}/onTheTools.jpg`,
    categories: { bodyType: ['ute', 'van'] },
    title: 'On the tools',
  },
];

const carouselName = 'lifestyle_carousel';
const tabs = [
  {
    id: carouselName,
    tabLabel: 'Lifestyle',
    content: {},
  },
];

export interface CarouselOccasionProps {}

export const CarouselOccasion: FC<CarouselOccasionProps> = () => {
  const { title } = getMicrocopyBulk({
    title: [MICROCOPY.HYGIENE_CAROUSEL_OCCASION_TITLE],
  });

  return (
    <CarouselSectionLegacy
      title={title}
      CarouselComponent={CarouselWrapper}
      tabs={tabs}
      carouselName={carouselName}
      className={classes.root}
    />
  );
};

const CarouselWrapper: FC = () => {
  const { plpPagesMap } = plpStoreLegacy((state) => ({ plpPagesMap: new Map(state.plpPagesMap) }), shallow);

  return (
    <CarouselSection
      tiles={tileDefinitions.map((item) => ({
        id: item.title,
        tile: <Tile {...item} key={item.occasion} plpPagesMap={plpPagesMap} />,
      }))}
    />
  );
};

const Tile: FC<CarouselOccasionDefinition & { plpPagesMap: Map<string, string> }> = ({
  cta,
  categories,
  image,
  occasion,
  plpPagesMap,
  title,
}) => (
  <ArticleCard
    className={classNames(classes.tile)}
    link={getPlpUrlFromStoreFilters(categories, {}, plpPagesMap)}
    linkTitle={title}
    data-testid={`carousel_occasion-tile-${occasion}`}
    onClick={() => {
      const event = {
        event: 'carousel_tile_click',
        name: carouselName,
        value: carouselName,
        index: 0,
      };
      pushToDataLayer(event);
    }}
  >
    <div className={classes.imageWrapper}>
      <Image src={image} width={400} height={500} alt="" />
    </div>
    <div className={classes.markdown}>{getMicrocopy(cta, true)}</div>
  </ArticleCard>
);
